<template>
  <div class="average_warp">
    <div class="left_biao">
      对比前一次的得分率变化(%)
      <p>(“—”表示前一次未训练)</p>
    </div>
    <div class="right_text">

      <div v-for="(item,index) in info"
           :key="index">

        <arrow-down-outlined v-if="Number(item.difference) <0"
                             style="color:#FF6147;font-size:28rem" />

        <arrow-up-outlined v-if="Number(item.difference) >0"
                           style="color:#3DDB91;font-size:28rem" />

        <minus-outlined v-if="Number(item.difference) ==0"
                        style="color:#37A0F4;font-size:28rem" />

        {{Number(item.difference)?item.difference:''}}
      </div>

    </div>
  </div>
</template>

<script setup>
import { ArrowUpOutlined, ArrowDownOutlined, MinusOutlined } from '@ant-design/icons-vue'
import { ref, onMounted } from 'vue'
let info = ref([])
const getInfo = (i) => {
  info.value = i
}
defineExpose({
  getInfo
})
/*export default {
  components: {
    ArrowUpOutlined
  },
  data () {
    return {
      info: [],
    }
  },
  mounted () { },
  methods: {
    getInfo (info) {
      this.info = info
    }
  }
}*/
</script>

<style lang="scss" scoped>
.average_warp {
  width: 100%;
  position: relative;
  border-right: 1px solid #e5e5e5;
  .left_biao {
    width: 100%;
    //height: 23px;
    font-size: 24rem;
    font-weight: bold;
    color: #333333;
    line-height: 33rem;
    text-indent: 1.5em;
    p {
      height: 28rem;
      font-size: 20rem;
      font-weight: bold;
      color: #999999;
      line-height: 28rem;
    }
  }
  .right_text {
    position: relative;
    right: 0;
    bottom: 0;
    font-size: 28rem;
    div {
      margin-left: 1.5em;
      &:nth-child(1) {
        margin-top: 23rem;
      }
      margin-top: 30rem;
      .yuan {
        width: 10px;
        height: 10px;
        display: inline-block;
        border-radius: 50%;
      }
    }
  }
}
</style>