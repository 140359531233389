<template>
  <div class="average_warp">
    <div class="left_biao">
      得分率（%）
    </div>
    <div v-for="(item,index) in info"
         class="warp"
         :key="index">
      <div class="line">
        <div class="progress"
             :style="{'width':getRate(item)+ '%'} "></div>
      </div>
      <div class="text">
        {{getRate(item)}}
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, defineExpose } from 'vue'
// let color = ref(['#67B8F8', '#5DEEDC', '#F9A66D', '#FBD46F', '#F36C6C', '#8771FD'])
let info = ref([])
let format = (percentage) => {
  return percentage
}
let getInfo = (info1) => {
  info.value = info1
}
let getRate = (item) => {
  if (Number(item.score) < 0) {
    return '0'
  } else {
    return item.score
  }
}
defineExpose({
  getInfo
})

</script>

<style lang="scss" scoped>
.average_warp {
  width: 100%;
  position: relative;

  .left_biao {
    width: 100%;
    height: 33rem;
    font-size: 24rem;
    font-weight: bold;
    color: #333333;
    line-height: 33rem;
    text-indent: 2em;
    margin-bottom: 51rem;
  }
}

.warp {
  display: flex;
  align-items: center;
  margin-bottom: 14.8px;

  .line {
    height: 16rem;
    // flex: 1;
    width: 80%;
    overflow: hidden;
    border-radius: 10px;
    .progress {
      height: 16rem;
      margin-left: 50rem;
      background: #2196f3;
      border-radius: 8rem;
    }
  }
  .text {
    width: 60px;
    // margin-right: 50rem;
    margin-left: 5rem;
    font-size: 28rem;
  }
}
</style>