<template>
  <!-- 弱点训练效果图 -->
  <div class="cishu_warp">
    <a-tabs v-model:activeKey="activeName"
            centered>
      <a-tab-pane :tab="item.subject_name"
                  v-for="(item) in subjectList"
                  :key="item.subject_id + ''"
                  :forceRender='false'>

        <myEcharts :list='info' />

      </a-tab-pane>

    </a-tabs>
  </div>
</template>

<script setup>
import * as echarts from 'echarts'
import { getParams } from '@/utils/report.js'
import { instance } from '@/utils/http.js'
import { inject, nextTick, onMounted } from '@vue/runtime-core'
import { ref, watch } from 'vue'
import myEcharts from './components/myEcharts.vue'

let activeName = ref('')
let info = ref([])
let value1 = inject('value1')
let value2 = inject('value2')
let subjectList = inject('subjectList')


const getInfo = async () => {
  if (activeName.value == 0) {
    return
  }
  info.value = []
  const { data } = await instance({
    url: '/api/v1/user/user_knowledge_train',
    method: 'get',
    params: getParams(activeName.value, value1, value2)
  })
  data.list.forEach(item => {
    let arr = [item.knowledge_desc, Number(item.last_score), Number(item.score)]
    info.value.push(arr)
  })
  // drawChar()

}

watch(activeName, () => {
  nextTick(() => {
    getInfo()
  })
})
onMounted(() => {
})
defineExpose({
  getInfo, activeName
})

</script>

<style lang="scss" scoped>
// .cishu_warp {
// }

.colors {
  display: flex;
  flex-wrap: wrap;
  margin-left: 7%;
  .item {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 10px;
    .yuan {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      margin-right: 10px;
    }
  }
}
.content {
  margin-top: 20px;
  margin-left: 4%;
  .title {
    font-size: 16px;
    font-weight: bold;
    color: #000000;
    line-height: 23px;
    height: 23px;
  }
  .tabs {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .tab {
      padding: 0 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 500;
      color: #333333;
      line-height: 30px;
      font-size: 14px;
      height: 30px;
      background: #f6f6f6;
      border-radius: 15px;
      margin-right: 10px;
      margin-bottom: 15px;
    }
  }
}
:deep .ant-tabs-tab {
  padding: 12rem;
  font-size: 24rem;
  margin: 0 30rem;
}
</style>