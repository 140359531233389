<template>
  <!-- 各题训练次数与得分率表格 -->
  <div class="cishu_warp">
    <a-tabs v-model:activeKey="activeName"
            centered>
      <a-tab-pane :tab="item.subject_name"
                  v-for="(item) in subjectList"
                  :key="item.subject_id + ''">
        <a-table :data-source="item1"
                 :columns="columns"
                 bordered>
          <template #bodyCell="{ column, record }">

            <template v-if="column.key === 'count'">
              <span style="color:#2196F3">
                {{record.count}}
              </span>
            </template>
            <template v-if="column.key === 'score'">
              <div>
                <a-progress :percent="Number(record.score)"
                            :format="format"
                            stroke-color="#2196F3"></a-progress>
                <a-progress :percent="Number(record.last_score)"
                            stroke-color="#FBD46F"
                            :format="format"></a-progress>
              </div>
              <!--  -->
            </template>
            <template v-if="column.key === 'difference'">
              <arrow-down-outlined v-if="Number(record.difference) < 0"
                                   style="color:#FF6147" />
              <arrow-up-outlined v-if="Number(record.difference) > 0"
                                 style="color:#3DDB91" />
              <minus-outlined class="el-icon-minus"
                              v-if="Number(record.difference) == 0"
                              style="color:#37A0F4" />
              <span style='font-size:26rem;font-weight:500'>{{Number(record.difference)}}</span>
            </template>
          </template>

        </a-table>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script setup>
import { ArrowUpOutlined, ArrowDownOutlined, MinusOutlined } from '@ant-design/icons-vue'
import { ref, defineExpose, inject, watch, nextTick } from 'vue'
import { instance } from '@/utils/http.js'
import { getParams } from '@/utils/report.js'
let columns = [
  {
    title: '题型',
    dataIndex: 'type_name',
    key: 'type_name',
    align: 'left'
  },
  /* {
    title: '子题型',
    dataIndex: 'subtype_name',
    key: 'subtype_name',
    align: 'left'
  }, */
  {
    title: '训练次数',
    dataIndex: 'count',
    key: 'count',
    align: 'center'
  },
  {
    title: '得分率(%)',
    dataIndex: 'score',
    key: 'score',
    align: 'center'
  },
  {
    title: '对比前一次的得分率变化(%)',
    key: 'difference',
    dataIndex: 'difference',
    align: 'center'
  }
];
let activeName = ref(0) //当前标签页


let item1 = ref([])
/* let value1 = ref([])
let value2 = ref([]) */
let subjectList = inject("subjectList")
let value1 = inject("value1")
let value2 = inject("value2")

let getInfo = async () => {

  if (activeName.value == 0) {
    return
  }

  const { data } = await instance({
    url: '/api/v1/user/user_type_report',
    method: 'get',
    params: getParams(activeName.value, value1.value, value2.value)
  })
  item1.value = data.list

}
let handleClick = (e) => {
}
let format = (value) => {
  return value
}
watch(activeName, () => {
  getInfo()
})
nextTick(() => {
  getInfo()
})
defineExpose({
  getInfo, activeName
})

/*export default {
  data () {
    return {
      activeName: '',
      item: []
    }
  },
  mounted () { },
  watch: {
    activeName () {
      this.getInfo()
    },
  },
  methods: {
    getInfo () {
      if (this.activeName == 0) return
      this.$http({
        url: '/api/v1/user/user_type_report',
        method: 'get',
        params: this.$getParams(this.activeName, this.$parent.value1, this.$parent.value2)
      }).then(res => {
        this.item = res.data.list

      })
    },
    handleClick (e) {
    },
    format (value) {
      return value
    }

  }
}*/
</script>

<style lang="scss" scoped>
.cishu_warp {
}
:deep .ant-tabs-content-holder {
  width: 95%;
  margin: auto;
}
:deep .ant-tabs-tab {
  padding: 12rem;
  font-size: 24rem;
  margin: 0 30rem;
}
:deep .ant-table {
  font-size: 24rem;
}
</style>