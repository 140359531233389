<template>
  <div>
    <div ref='mainRef'
         :style="{width:'100%',height:'400rem'}"></div>
  </div>
</template>


<script setup>
import { icons } from 'ant-design-vue/lib/image/PreviewGroup'
import * as echarts from 'echarts'

import { ref, onMounted, defineExpose, toRefs, nextTick, watch, onUnmounted } from 'vue'
let props = defineProps(
  {
    list: {
      type: Array,
      default: []
    }
  }
)
let mainRef = ref()
let list = toRefs(props).list
let myChart
const drawChar = () => {
  if (myChart != null && myChart != "" && myChart != undefined) {
    myChart.dispose(); //销毁
  }
  myChart = echarts.init(mainRef.value)
  myChart.setOption({
    legend: {
      icon: 'circle',
    },
    tooltip: {},
    textStyle: {
      fontSize: '15'
    },
    grid: {
      top: '10%'
    },
    color: ['#F2BF3B', '#2196F3'],
    dataset: {
      source: [
        //  ['product', '前一次得分率', '本次得分率'],
        ...list.value
      ],

    },
    xAxis: [{
      type: 'category',
      // nameTextStyle不生效
      // nameTextStyle: {
      //   fontSize: '15'
      // },
      axisLabel: {
        show: true,
        textStyle: {
          fontSize: '15'
        }
      }
    }],

    yAxis: [{
      max: 100,
      min: 0,
      axisLabel: {
        show: true,
        textStyle: {
          fontSize: '15'
        }
      }
    }],
    dataZoom: [
      {
        type: 'slider',
        start: 0,
        end: 30
      },
    ],
    series: [{
      type: 'bar', label: {
        show: true,
        position: 'top'
      }
    }, {
      type: 'bar', label: {
        show: true,
        position: 'top'
      }
    }]
  }, true)
  window.addEventListener('resize', chartResize)
}
const chartResize = () => {
  myChart.resize()
}
const clear = () => {
  myChart = myChart.dispose()
}
onMounted(() => {
  nextTick(() => {
    drawChar()
  })
})
watch(list, () => {
  nextTick(() => {
    drawChar()
  })
}, { deep: true })
onUnmounted(() => {
  clear()
  window.removeEventListener('resize', chartResize)
})
defineExpose({
  drawChar
})
</script>

<style>
</style>