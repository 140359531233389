<template>
  <div class="average_warp">
    <div class="left_biao">
      <div style='margin-bottom:20rem'>训练次数</div>
      <div id="myChart"
           ref='chartRef'
           :style="{width: '100%', height: '420rem'}"></div>
    </div>
    <div class="right_text">
      <div v-for="(item,index) in info"
           :key="index">
        <span class="yuan"
              :style="{'background':color[index]}"></span>
        {{item.name}}
        {{item.value }}次
      </div>
    </div>
  </div>
</template>

<script setup>
import * as echarts from 'echarts'
import { ref } from 'vue'
let color = ref(['#67B8F8', '#5DEEDC', '#F9A66D', '#F36C6C', '#f3b2b2'])
let count = ref()
let textList = ref([])
let info = ref([])
let randomHexColor = () => { //随机生成十六进制颜色
  var hex = Math.floor(Math.random() * 16777216).toString(16); //生成ffffff以内16进制数
  while (hex.length < 6) { //while循环判断hex位数，少于6位前面加0凑够6位
    hex = '0' + hex;
  }
  let color1 = '#' + hex; //返回‘#'开头16进制颜色
  if (color.value.indexOf(color1) != -1) {
    return randomHexColor()
  }
  return color1
}
let getInfo = (list) => {
  count.value = 0
  info.value = []
  list.forEach((item, index) => {
    count.value += item.count
    let obj = {
      name: item.module_name,
      value: item.count
    }
    info.value.push(obj)
  })
  drawLine()
}
let chartRef = ref()

const drawLine = () => {
  // 基于准备好的dom，初始化echarts实例
  let myChart = echarts.init(chartRef.value)
  // 绘制图表
  myChart.setOption({
    tooltip: {
      trigger: 'item',
      position: [20, 20]
    },
    legend: {
      top: '5%',
      left: 'center',
      show: false
    },
    graphic: {
      type: "text",
      left: "center",
      top: "42%",
      style: {
        text: `{d|总计\n} {f|${count.value}次}`,
        rich: {
          d: {
            color: '#666',
            fontSize: 20 + 'rem',
            padding: 5,
            align: 'center',

          },
          f: {
            color: '#000',
            fontSize: 36 + 'rem',
            fontWeight: 'bold',
            align: 'center',
            padding: 8
          }
        }
      }

    },
    color: color.value,
    series: [
      {
        type: 'pie',
        radius: ['60%', '80%'],
        avoidLabelOverlap: false,
        itemStyle: {
          borderRadius: 2,
          borderColor: '#fff',
          borderWidth: 1
        },
        label: {
          show: false,
          position: 'center'
        },
        emphasis: {
          label: {
            // 配置鼠标移上去在饼中间显示文字
            show: false,
            fontSize: '20',
            fontWeight: 'bold'
          }
        },
        labelLine: {
          show: false
        },
        data: info.value
      }
    ]
  })

  window.onresize = (() => {
    myChart.resize()
  })

}
defineExpose({ getInfo })


</script>

<style lang="scss" scoped>
.average_warp {
  width: 100%;
  display: flex;
  position: relative;
  border-right: 1px solid #e5e5e5;
  .left_biao {
    width: 100%;
    font-size: 24rem;
    font-weight: bold;
    color: #333333;
    line-height: 33rem;
    text-indent: 1.5em;
  }
  .right_text {
    width: 55%;
    position: relative;
    right: 0;
    bottom: 0;
    font-size: 24rem;
    div {
      &:nth-child(1) {
        margin-top: 85rem;
      }
      margin-top: 20rem;
      .yuan {
        width: 20rem;
        height: 20rem;
        display: inline-block;
        border-radius: 50%;
      }
    }
  }
}
</style>