<template>
  <div class="cishu_warp">
    <a-tabs v-model:activeKey="activeName"
            centered>
      <a-tab-pane :tab="item.subject_name"
                  v-for="(item) in subjectList"
                  :key="item.subject_id+''">
        <div class="txt">
          <div>1.{{summary.text1}} </div>
          <div>2.{{summary.text2}} </div>
        </div>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script setup>
import { ref, reactive, inject, watch, nextTick } from 'vue'
import { instance } from '@/utils/http.js'
let value1 = inject('value1')
let value2 = inject('value2')
let subjectList = inject('subjectList')
let activeName = ref(0)
let summary = ref({})
watch(activeName, () => {
  getInfo()
})
const getInfo = async () => {
  if (activeName.value == 0) {
    return
  }
  const { data } = await instance({
    url: '/api/v1/user/learning_analysis',
    method: 'get',
    params: {
      subject_id: activeName.value,
      this_weak_start_time: value1 ? value1[0] : '',
      this_weak_end_time: value1 ? value1[1] : '',
    }
  })
  summary.value = data.user_subject_conclusion

}
nextTick(() => {
  getInfo()
})
defineExpose({
  getInfo, activeName
})
/*export default {
  data () {
    return {
      subjectList: [],
      activeName: 0,
      summary: {}
    }
  },
  mounted () {

  },
  watch: {
    activeName () {
      this.getInfo()
    }
  },
  methods: {
    getInfo () {
      if (this.activeName == 0) {
        return
      }
      this.$http({
        url: '/api/v1/user/learning_analysis',
        method: 'get',
        params: {
          subject_id: this.activeName,
          this_weak_start_time: this.$parent.value1 ? this.$parent.value1[0] : '',
          this_weak_end_time: this.$parent.value1 ? this.$parent.value1[1] : '',
        }
      }).then(res => {
        this.summary = res.data.user_subject_conclusion
      })
    },
    handleClick (e) {
      console.log(e.index);
    },
  }
}*/
</script>

<style lang="scss" scoped>
.cishu_warp {
}
:deep .ant-tabs-tab {
  padding: 12px 8px;
}
.txt {
  padding: 0 84rem;
  font-size: 24rem;
  line-height: 2em;
}
:deep .ant-tabs-tab {
  padding: 12rem;
  font-size: 24rem;
  margin: 0 30rem;
}
</style>