<template>
  <div class="cishu_warp">
    <a-tabs v-model:activeKey="activeName"
            centered>
      <a-tab-pane :tab="item.subject_name"
                  v-for="(item) in subjectList"
                  :key="item.subject_id + ''">
        <div style="text-align: center;">
          <a-table :dataSource="info"
                   :columns="column"
                   :pagination='false'
                   bordered>
            <template #bodyCell="{ column, index,record }">
              <template v-if="column.key === 'index'"> {{index+1}} </template>
              <template v-if="column.key === 'count'">
                <span style="color:#40A4F4">
                  {{record.count}}
                </span>
              </template>
              <template v-if="column.key === 'rate'">
                <a-progress :percent="Number(record.rate)"
                            :format="format"></a-progress>
              </template>

            </template>
          </a-table>
        </div>

      </a-tab-pane>
    </a-tabs>

    <div class="content"
         v-if="ruodianArr.length">
      <div class="title">
        训练次数小于2次的章节
      </div>
      <div class="tabs">
        <div class="tab"
             v-for="item in ruodianArr"
             :key="item.knowledge_id">
          {{item.knowledge_desc}}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { column } from './property.js'
import { getParams } from '@/utils/report.js'
import { instance } from '@/utils/http.js'
import { inject, ref, watch } from 'vue'
let activeName = ref('')
let info = ref([])
let ruodianArr = ref([])
let value1 = inject('value1')
let value2 = inject('value2')
let subjectList = inject('subjectList')
watch(activeName, () => {
  getInfo()
})
const getInfo = async () => {
  if (activeName.value == 0) {
    return
  }
  const { data } = await instance({
    url: '/api/v1/report/chapter_report',
    method: 'get',
    params: getParams(activeName.value, value1.value, value2.value)
  })

  info.value = data.weak
  ruodianArr.value = data.weak.filter(item => item.count < 2)

}
const format = (value) => {
  return value
}
defineExpose({
  getInfo, activeName
})
/*export default {
  data () {

    return {
      activeName: '',
      info: [],
      ruodianArr: [],

    }
  },
  mounted () {
  },
  watch: {
    activeName () {
      this.getInfo()
    }
  },
  methods: {

    format (value) {
      return value
    },
    getInfo () {
      if (this.activeName == 0) {
        return
      }
      this.$http({
        url: '/api/v1/report/chapter_report',
        method: 'get',
        params: getParams(this.activeName, this.$parent.value1, this.$parent.value2)
      }).then(res => {
        this.info = res.data.weak
        this.ruodianArr = res.data.weak.filter(item => item.count < 2)
      })
    },
    handleClick (e) {

    },

  }
}*/
</script>

<style lang="scss" scoped>
.cishu_warp {
  .content {
    margin-left: 4%;
    margin-top: 20px;
    .title {
      font-size: 16px;
      font-weight: bold;
      color: #000000;
      line-height: 23px;
      height: 23px;
    }
    .tabs {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      .tab {
        padding: 0 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        color: #333333;
        line-height: 30px;
        font-size: 14px;
        height: 30px;
        background: #f6f6f6;
        border-radius: 15px;
        margin-right: 10px;
        margin-bottom: 15px;
      }
    }
  }
}
/* ::v-deep .el-tabs__nav .is-top {
  margin: 0 auto;
  width: 120px;
  text-align: center;
  font-size: 18px;
}
::v-deep .el-tabs__nav-scroll {
  display: flex;
  justify-content: center;
}
 */

:deep .ant-tabs-content-holder {
  width: 95%;
  margin: auto;
}
:deep .ant-tabs-tab {
  padding: 12rem;
  font-size: 24rem;
  margin: 0 30rem;
}
:deep .ant-table {
  font-size: 24rem;
}
/* ::v-deep .el-progress-bar__outer {
  background: none;
} */
</style>