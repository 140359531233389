export const column = [{
  title: '序号',
  dataIndex: 'index',
  key: 'index',
  align: 'center'
},
{
  title: '章节',
  dataIndex: 'chapter_desc',
  key: 'chapter_desc',
  align: 'center'
},
{
  title: '训练次数',
  dataIndex: 'count',
  key: 'count',
  align: 'center',
  width: "150"
},
{
  title: '得分率(%)',
  dataIndex: 'rate',
  key: 'rate',
  align: 'center'
}]