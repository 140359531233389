<template>
  <div class="cishu_warp">
    <a-tabs v-model:activeKey="activeName"
            centered>
      <a-tab-pane :tab="item.subject_name"
                  v-for="(item) in subjectList"
                  :key="item.subject_id + ''">
        <div style="text-align: center;">
          <a-table :dataSource="info"
                   :columns="column"
                   :pagination="false"
                   bordered>
            <template #bodyCell="{ column, record,index }">
              <template v-if="column.key === 'index'">
                <span>
                  {{index+1}}
                </span>
              </template>
              <template v-if="column.key === 'count'">
                <span style="color:#40A4F4">
                  {{record.count}}
                </span>
              </template>
              <template v-if="column.key === 'score'">
                <a-progress :percent="Number(record.score)"
                            :format="format"></a-progress>
              </template>

            </template>
          </a-table>
        </div>
      </a-tab-pane>
    </a-tabs>

    <div class="content"
         v-if="ruodianArr.length">
      <div class="title">
        训练次数小于2次的考点
      </div>
      <div class="tabs">
        <div class="tab"
             v-for="item in ruodianArr"
             :key="item.knowledge_id">
          {{item.knowledge_desc}}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>

import { getParams } from '@/utils/report.js'
import { instance } from '@/utils/http.js'
import { inject, ref, watch } from 'vue'
let activeName = ref('')
let info = ref([])
let ruodianArr = ref([])
let value1 = inject('value1')
let value2 = inject('value2')
let subjectList = inject('subjectList')
let column = [{
  title: '序号',
  dataIndex: 'index',
  key: 'index',
  align: 'center'
},
{
  title: '考点',
  dataIndex: 'knowledge_desc',
  key: 'knowledge_desc',
  align: 'center'
},
{
  title: '训练次数',
  dataIndex: 'count',
  key: 'count',
  align: 'center',
  width: "150"
},
{
  title: '得分率(%)',
  dataIndex: 'score',
  key: 'score',
  align: 'center'
}]

watch(activeName, () => {
  getInfo()
})
const getInfo = async () => {
  if (activeName.value == 0) {
    return
  }
  const { data } = await instance({
    url: '/api/v1/user/user_knowledge_report',
    method: 'get',
    params: getParams(activeName.value, value1.value, value2.value)
  })

  info.value = data.list.more
  ruodianArr.value = data.list.less
}
const format = (value) => {
  return value
}
defineExpose({
  getInfo, activeName
})
/*export default {
  data () {
    return {
      activeName: '',
      info: [],
      ruodianArr: []
    }
  },
  mounted () {
  },
  watch: {
    activeName () {
      this.getInfo()
    }
  },
  methods: {
    format (value) {
      return value
    },
    getInfo () {
      if (this.activeName == 0) {
        return
      }
      this.$http({
        url: '/api/v1/user/user_knowledge_report',
        method: 'get',
        params: this.$getParams(this.activeName, this.$parent.value1, this.$parent.value2)
      }).then(res => {
        this.info = res.data.list.more
        this.ruodianArr = res.data.list.less
      })
    },
    handleClick (e) {

    },

  }
}*/
</script>

<style lang="scss" scoped>
.cishu_warp {
}
::v-deep .a-tabs__nav .is-top {
  margin: 0 auto;
  width: 120px;
  text-align: center;
  font-size: 18px;
}
::v-deep .a-tabs__nav-scroll {
  display: flex;
  justify-content: center;
}

.content {
  margin-left: 4%;
  margin-top: 20px;
  .title {
    font-size: 24rem;
    font-weight: bold;
    color: #000000;
    line-height: 33rem;
    height: 33rem;
    margin-bottom: 20rem;
  }
  .tabs {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .tab {
      padding: 10rem 41rem;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 500;
      color: #333333;
      //line-height: 30px;
      font-size: 24rem;
      //height: 30px;
      background: #f6f6f6;
      border-radius: 30rem;
      margin-right: 20rem;
      margin-bottom: 30rem;
    }
  }
}

:deep .ant-tabs-content-holder {
  width: 95%;
  margin: auto;
}
:deep .ant-tabs-tab {
  padding: 12rem;
  font-size: 24rem;
  margin: 0 30rem;
}
:deep .ant-table {
  font-size: 24rem;
}
</style>